import dayjs from 'dayjs'
import { trim } from 'lodash'

// add utcOffset for date time
export function addOffsetUTC(
  time: string | number | Date | undefined,
  utcOffset: number
) {
  var targetTime = time ? new Date(time) : new Date()
  // Get the timezone offset from local time in minutes
  var tzDifference = utcOffset * 60 + targetTime.getTimezoneOffset()
  // Convert the offset to milliseconds, add to targetTime, and make a new Date
  var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000)

  // Convert to UTC and then format
  return offsetTime
}

// display UTC offset
export const getUTCString = (offset: number) => {
  const hh = Math.abs(offset).toString().padStart(2, '0')

  if (offset > 0) {
    return `UTC+${hh}:00`
  }
  if (offset < 0) {
    return `UTC-${hh}:00`
  }

  return 'UTC±00:00'
}

export const format = (
  date: string | number | Date | undefined,
  locale?: string,
  fmt = 'YYYY-MM-DD HH:mm:ss',
  utcOffset: number | undefined = undefined
) => {
  // @ts-ignore
  const currentTZOffsetInHours =
    utcOffset === undefined || utcOffset === null
      ? -(new Date().getTimezoneOffset() / 60)
      : utcOffset
  const withUTC = fmt.indexOf('Z') >= 0 // display UTC offset

  return (
    dayjs(addOffsetUTC(date, currentTZOffsetInHours))
      .locale(locale ?? 'en')
      .format(withUTC ? trim(fmt, 'Z') : fmt) +
    (withUTC ? getUTCString(currentTZOffsetInHours) : '')
  )
}

export const toLocal = (time: number | string | undefined) => {
  if (time) {
    var form
    if (typeof time === 'string') {
      form = new Date(time)
    } else {
      form = new Date(time * 1000)
    }
    const options = {
      hour12: false
    }
    const local = form.toLocaleString(undefined, options)
    return local
  }
  return undefined
}

export const formatTime = format

export default dayjs
