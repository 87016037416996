/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type IamSourceType = typeof IamSourceType[keyof typeof IamSourceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IamSourceType = {
  policy: 'policy',
  role: 'role',
  user_group: 'user_group',
} as const;
