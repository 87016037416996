/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type IamRoleBindingTargetType = typeof IamRoleBindingTargetType[keyof typeof IamRoleBindingTargetType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IamRoleBindingTargetType = {
  user: 'user',
  user_group: 'user_group',
  api_key: 'api_key',
} as const;
