/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type IamPolicyType = typeof IamPolicyType[keyof typeof IamPolicyType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IamPolicyType = {
  custom: 'custom',
  inline: 'inline',
} as const;
